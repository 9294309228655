import React, { useEffect, useState } from 'react';
import style from './cookieConsent.module.scss';

const CookieAcceptStorageKey = 'pulzonCookieAccept';

const CookieConsent = () => {
  const [active, setActive] = useState(true);

  useEffect(() => {
    const isCookieAccept = localStorage.getItem(CookieAcceptStorageKey) === 'accepted';
    setActive(!isCookieAccept);
  }, []);

  const close = () => {
    setActive(false);
    localStorage.setItem(CookieAcceptStorageKey, 'accepted');
  };

  if (!active) return null;

  return (
    <div className={style.consentContainer}>
      <div className={style.header}>
        <h3>Cookies</h3>
        <span className={style.closeBtn} role="button" onClick={close} tabIndex={-1}>
          X
        </span>
      </div>
      <p>
        This website or its third-party tools use cookies or other identifiers. Learn more:{' '}
        <a href="https://app.pulzon.com/privacyAndPolicy.html">Privacy policy</a>. <br />
        You accept the use of cookies or other identifiers by closing this notice.
      </p>
    </div>
  );
};

export default CookieConsent;
