import React, { useEffect } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import CookieConsent from '../components/cookieConsent';
import style from './index.module.scss';
import firebase from 'gatsby-plugin-firebase';

const IndexPage = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    firebase.analytics().logEvent('visited_landing_page');
  }, []);

  return (
    <Layout>
      <SEO title="Home" />
      <section className={style.introSection}>
        <div className={style.introText}>
          <h1>
            Do you know <span className={style.textHighlight}>people</span> in your local area who are{' '}
            <span className={style.textHighlight}>working at home like you?</span>
          </h1>
          <div className={style.smallDescription}>
            <p className={style.textHighlight}>Reach remote workers in your area and join to real time events.</p>
            <p>
              Have a lunch with nice people in your favorite restaurant or drink some coffee together and talk about
              your current work...
            </p>
          </div>
          <a href="https://app.pulzon.com/registration">
            <button type="button" className={style.getStartedButton}>
              GET STARTED
            </button>
          </a>
        </div>
        <div className={style.bgImage} />
      </section>
      <CookieConsent />
    </Layout>
  );
};

export default IndexPage;
